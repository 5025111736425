import React from 'react';
import AuthorBlock from './AuthorBlock.js';
import ShareButtons from './ShareButtons.js';
import MarkdownConversion from './MarkdownConversion.js';

function Content(props) {
    return (
        <div>
            <img className="not_mobile" src={props.splash} style={{ paddingTop: "3%", paddingLeft: "23%", paddingRight: "23%" }} />
            <img className="mobile" src={props.splash} style={{ paddingTop: "0%" }} />

            <AuthorBlock avatar={props.avatar} author={props.author} date={props.date} authorBlurb={props.authorBlurb} />

            

            <MarkdownConversion markdownDoc={props.markdownDoc} />

            <div className="Demo__some-network uk-margin-top">
                <h3>Support us with a share!</h3>
                <ShareButtons link={props.link} />
                <div className="Demo__some-network__share-count">&nbsp;</div>
            </div>
        </div>
    )
}

export default Content