import React from 'react';
import './App.css';

import BaseCard3 from './Components/BaseCard3.js';
import Content from './Components/Content.js';
import Sidebar from './Components/Sidebar.js';
import MarkdownConversion from './Components/MarkdownConversion.js';
import AboutBios from './Components/AboutBios.js';
import Newsletter from "./Components/Newsletter.js";
import Footer from './Components/Footer.js';

// Posts
import MotherhoodEmpowered from './Components/markdown/posts/MotherhoodEmpowered.md'
import WhyWeCosleep from './Components/markdown/posts/WhyWeCosleep.md';
import BoobsForBabies from './Components/markdown/posts/BoobsForBabies.md';
import WhatPattern from './Components/markdown/posts/WhatPattern.md';
import L8trH8tr from './Components/markdown/posts/L8trH8tr.md';
import OnNotRuiningBirthdays from './Components/markdown/posts/OnNotRuiningBirthdays.md';

// Constant markdowns
import about from './Components/markdown/About.md'
import store from './Components/markdown/Store coming soon.md'
import services from './Components/markdown/Services.md'
import newsletter from './Components/markdown/Newsletter.md'

import bamflogo from './svg/bamflogo.svg';
import circlelogo from './svg/circlelogo.svg';
import mediumlogo from './svg/mediumlogo.svg';
import smallerlogo from './svg/smallerlogo.svg';
import smalllogo from './svg/smalllogo.svg';

// Splash Images
import MotherhoodEmpoweredSplash from './svg/MotherhoodEmpoweredSplash.svg';
import WhyWeCosleepSplash from './svg/WhyWeCosleepSplash.svg';
import BoobsForBabiesSplash from './svg/BoobsForBabies.svg';
import WhatPatternSplash from './svg/WhatPatternSplash.gif';
import L8trH8trSplash from './svg/boobie.gif';
import OnNotRuiningBirthdaysSplash from './svg/OnNotRuiningBirthdaysSplash.gif'

// TODO: modify AuthorBlock.js to use a modification of the author property
import Karen from './svg/avatars/Karen.svg';
import Jay from './svg/avatars/Jay.svg';
import Ameerah from './svg/avatars/Ameerah.svg';
import Rae from './svg/avatars/Rae.svg';

import './fontFile/stylesheet.css';



import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

/*
const contentRoutesArrayExperimental = [
  {
    link: "EmpoweredMotherhood",
    splash: { EmpoweredMotherhood },
    author: "Karen",
    title: "Empowering Motherhood"
  },
  {
    link: "Motherhood2",
    splash: { splash1 },
    author: "Jay",
    title: "Element 2"
  }
]
*/



const contentRoutesArray = [
  <Route path="/OnNotRuiningBirthdays">
    <Content
      title="On Not Ruining Birthdays"
      splash={OnNotRuiningBirthdaysSplash}
      author="Jay"
      avatar={Jay}
      date="June 10th, 2020"
      authorBlurb="Co-founder and CTO of the BAMF blog."
      markdownDoc={OnNotRuiningBirthdays}
      link="/OnNotRuiningBirthdays"
    />
  </Route>,
  <Route path="/L8trH8tr">
    <Content
      title="L8tr H8tr"
      splash={L8trH8trSplash}
      author="Karen"
      avatar={Karen}
      date="May 27th, 2020"
      authorBlurb="Boss mom and BAMF spear-header."
      markdownDoc={L8trH8tr}
      link="/L8trH8tr"
    />
  </Route>,
  <Route path="/WhatPattern">
    <Content
      title="What Pattern?"
      splash={WhatPatternSplash}
      author="Jay"
      avatar={Jay}
      date="April 29nd, 2020"
      authorBlurb="Co-founder and CTO of the BAMF blog."
      markdownDoc={WhatPattern}
      link="/WhatPattern"
    />
  </Route>,
  <Route path="/BoobsForBabies">
    <Content
      title="Boobs are for Babies"
      splash={BoobsForBabiesSplash}
      author="Karen"
      avatar={Karen}
      date="April 22nd, 2020"
      authorBlurb="Boss mom and BAMF spear-header."
      markdownDoc={BoobsForBabies}
      link="/BoobsForBabies"
    />
  </Route>,
  <Route path="/WhyWeCosleep">
    <Content
      title="Why We Co-sleep"
      splash={WhyWeCosleepSplash}
      author="Karen"
      avatar={Karen}
      date="April 8th, 2020"
      authorBlurb="Boss mom and BAMF spear-header."
      markdownDoc={WhyWeCosleep}
      link="/WhyWeCosleep"
    />
  </Route>,
  <Route path="/MotherhoodEmpowered">
    <Content
      title="Motherhood Empowered"
      splash={MotherhoodEmpoweredSplash}
      author="Karen"
      avatar={Karen}
      date="April 1st, 2020"
      authorBlurb="Boss mom and BAMF spear-header."
      markdownDoc={MotherhoodEmpowered}
      link="/MotherhoodEmpowered" />
  </Route>
]

const contentLinksArray = [
  <BaseCard3
    link="/OnNotRuiningBirthdays"
    splash={OnNotRuiningBirthdaysSplash}
    avatar={Jay}
    title="On Not Ruining Birthdays"
    blurb="Rae turns one this week and in celebration of that, I want to break this post into two distinct parts. In the first part I will highlight "
  />,
  <BaseCard3
    link="/L8trH8tr"
    splash={L8trH8trSplash}
    avatar={Karen}
    title="L8tr H8tr"
    blurb="From a very young age, we are conditioned to be dissatisfied in the vessel we are born into. I was eight years"
  />,
  <BaseCard3
    link="/WhatPattern"
    splash={WhatPatternSplash}
    avatar={Jay}
    title="What Pattern?"
    blurb="Tell me if this sounds familiar: You have a friend or a partner and they love life. This person laughs easily and listens easier. They check-in with you whenever you mention you’ve had a hard day and tend"
  />,
  <BaseCard3
    link="/BoobsForBabies"
    splash={BoobsForBabiesSplash}
    avatar={Karen}
    title="Boobs are for Babies"
    blurb="Since becoming a breastfeeding mother, my breasts now have a purpose unrelated to sex: To nourish and comfort my child. My breasts have transformed from a superficial entity to having the biological funct"
  />,
  <BaseCard3
    link="/WhyWeCosleep"
    splash={WhyWeCosleepSplash}
    avatar={Karen}
    title="Why We Co-sleep"
    blurb="Rae sleeps in our bed and always has. Sometimes this confuses people. They ask questions such as: Why? How does that work? Aren’t you nervous"
  />,
  <BaseCard3
    link="/MotherhoodEmpowered"
    splash={MotherhoodEmpoweredSplash}
    avatar={Karen}
    title="Motherhood Empowered"
    blurb="Empowerment: I have a love-hate relationship with the word. When I think of the concept of empowerment I simultaneously feel an uncontrollable call-to-ac" />
]

const mobileRoutesArray = [
  <Route path="/about">
    <h3>Meet the BAMF team</h3>
    
    <AboutBios
      avatar={Karen}
      author={"Karen"}
      authorBlurb={"Content creator, head of milk production, and co-founder of the BAMF blog. Amateur social advocate and full-time mom. Master multi-tasker and avid critical thinker. Loves expensive coffee. Really good at making messes. "}
    />
    <AboutBios
      avatar={Rae}
      author={"Rae"}
      authorBlurb={"Baby. Breast milk sommelier and carpet inspector. Manages a busy schedule but gets in two naps a day. Learning to stand and looking to promote into a walking position within the organization."}
    />
    <AboutBios
      avatar={Jay}
      author={"Jay"}
      authorBlurb={"Co-founder and CTO of the BAMF blog. Professional baby carrier and car packer. Specializes in doing things Karen doesn't want to do while not complaining too much about it. Vegan sympathizer and closeted meat eater. 🌭"}
    />
    <AboutBios
      avatar={Ameerah}
      author={"Ameerah"}
      authorBlurb={"Ameerah: Illustrator, recent graduate, and aspiring juggler. Can be found on Lull Island most days (1907-0885-3877). No kids or pets, just lotsa plant friends to take care of  🍃"}
    />
    <MarkdownConversion markdownDoc={about} />
  </Route>,
  <Route path="/services">
    <MarkdownConversion markdownDoc={services} />
  </Route>,
  <Route path="/store">
    <MarkdownConversion markdownDoc={store} />
  </Route>,
  <Route path="/newsletter">
    <MarkdownConversion markdownDoc={newsletter} />
  </Route>
]

{/*
{contentRoutesArrayExperimental.map(element => (
  <div>
    <BaseCard3
      link={element.link}
      splash={element.splash}
      author={element.author}
      title={element.title} />

    <Route path={"/" + element.link}>
      <Content title={element.title} splash={element.splash} />
    </Route>
  </div>
))}
*/}

function App() {
  return (
    <div className="App">
      <Router>
        <Sidebar logo={bamflogo} />
        {mobileRoutesArray.map(element => (element))}

        <Switch>
          {contentRoutesArray.map(element => (element))}

          {/* The home route of "/" contains routes to all the content,
          each of which contain links back to the "/" page */}
          <Route path="/">
            <div
              className="not_mobile"
              style={{
                paddingTop: 40,
                paddingRight: "24%",
                paddingBottom: 10,
                paddingLeft: "24%"
              }}
            >
              <img src={mediumlogo} />
              <h3 align="left">
                We are Rae, Jay, and Karen. Together with you--we form the Bay Area Millennial Family.
                Join us on our parenting journey as we spread love and empowerment throughout the 7th largest economy in the world.
              </h3>
            </div>

            {contentLinksArray.map(element => (element))}
          </Route>

        </Switch>
      </Router>

      <Router>
        <Switch>
          <Route path="/newsletter">
            <MarkdownConversion markdownDoc={newsletter} />
          </Route>
          <Route path="/">
            
            <Footer />
          </Route>


        </Switch>
      </Router>
    </div>
  )
}

export default App;
