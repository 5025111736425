import React from 'react';
import MobileMenu from './MobileMenu.js';

import { Link } from "react-router-dom";
import { scrollToTop } from "../config.js";



class Sidebar extends React.Component {
    state = {}



    render() {
        return (
            <div>
                <div className="not_mobile">
                    <nav className="uk-navbar-container uk-width-1-1" uk-navbar="true" uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; show-on-up: true; animation: uk-animation-slide-top;" >

                        <div className="uk-navbar-center">

                            <div className="uk-navbar-center-left">
                                <div>
                                    <ul className="uk-navbar-nav">
                                        <li className="">
                                            <Link to="/about">
                                                <div onClick={() => (scrollToTop())} style={{ paddingTop: 40 }} >About</div>
                                            </Link>
                                        </li>
                                        <li className="">
                                            <Link to="/store">
                                                <div onClick={() => (scrollToTop())} style={{ paddingTop: 40 }} >Store</div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <a className="uk-navbar-item" href="#">
                                <Link to="/">
                                    <img
                                        src={this.props.logo}
                                        onClick={() => (scrollToTop())}
                                        height="150"
                                        width="250"
                                        style={{
                                            paddingTop: 10,
                                            paddingRight: 5,
                                            paddingBottom: 10,
                                            paddingLeft: 35
                                        }}
                                    />
                                </Link>
                            </a>

                            <div className="uk-navbar-center-right">
                                <div>
                                    <ul className="uk-navbar-nav">
                                        <li className="">
                                            <Link to="services">
                                                <div onClick={() => (scrollToTop())} style={{ paddingTop: 40 }}>Services</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <ul className="uk-navbar-nav">
                                                <li className="">
                                                    <a href="#" style={{ paddingTop: 40 }}>
                                                        <span className="uk-icon uk-margin-small-right" uk-icon="icon: star"></span>
                                                        <span>Support</span>
                                                        <span className="uk-icon uk-margin-small-left" uk-icon="icon: star"></span>
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="uk-navbar-dropdown" uk-dropdown="mode: click; pos: bottom-justify; offset: 10">
                                                <ul className="uk-nav uk-navbar-dropdown-nav">
                                                    <li>
                                                        <a href="https://www.patreon.com/bayareamillennialfamily">Patreon</a>
                                                    </li>
                                                    <Link style={{ textDecoration: "none" }} to="newsletter">
                                                        <div onClick={() => (scrollToTop())}>Newsletter</div>
                                                    </Link>
                                                    <li>
                                                        <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=2BN3DXG5MMXVU&item_name=All+proceeds+directly+fund+Rae%27s+education%21&currency_code=USD&source=url">Donate</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </nav>
                </div>


                <div className="mobile">
                    <nav className="uk-navbar-container uk-navbar uk-width-1-1" uk-navbar="true" uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-stickyuk-navbar-sticky; show-on-up: true; animation: uk-animation-slide-top;">

                        <div class="uk-navbar-left">

                            <div className="uk-navbar" uk-grid="true">

                                <ul className="uk-navbar-nav">
                                    <li className="uk-width-expand">
                                        <Link to="/">
                                            <img
                                                onClick={() => (scrollToTop())}
                                                style={{
                                                    paddingTop: 10,
                                                    paddingRight: 5,
                                                    paddingBottom: 10,
                                                    paddingLeft: 35
                                                }}
                                                className=""
                                                src={this.props.logo}
                                                height="100"
                                                width="250" />
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div class="uk-navbar-right">
                            <ul>
                                <li className="uk-width-auto uk-align-center">

                                    <span
                                        className="uk-icon uk-align-center"
                                        uk-icon="icon: menu; ratio: 2.5"
                                        uk-toggle="target: #offcanvas-usage"
                                        style={{
                                            paddingTop: 55,
                                            paddingRight: 30,
                                            paddingBottom: 10,
                                            paddingLeft: 25
                                        }}
                                    />

                                    <div id="offcanvas-usage" uk-offcanvas="mode: push; overlay: true; flip: true">
                                        <div className="uk-offcanvas-bar">
                                            <button className="uk-offcanvas-close" type="button" uk-close="true"></button>
                                            <MobileMenu />
                                        </div>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>

            </div >

        )
    }
}

export default Sidebar