import React from 'react';
import { Link, Route, Switch } from "react-router-dom";

import mobileRoutesArray from "../config.js";
import { scrollToTop } from "../config.js";


function MobileMenu() {
    return (
        <div>
            <div className="uk-margin-top uk-align-center">
                <ul className="uk-list uk-list-large">
                    <li>
                        <Link to="/about">
                            <div onClick={() => (scrollToTop())} >About</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/store">
                            <div onClick={() => (scrollToTop())} >Store</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/services">
                            <div onClick={() => (scrollToTop())} >Services</div>
                        </Link>
                    </li>
                    <hr class="uk-divider-small"></hr>
                    <li>
                        <span className="uk-icon uk-margin-small-right" uk-icon="icon: star"></span>
                        <span >Support by:</span>
                        <span className="uk-icon uk-margin-small-left" uk-icon="icon: star"></span>

                    </li>
                    <li>
                        <a href="https://www.patreon.com/bayareamillennialfamily">Patreon</a>
                    </li>
                    <li>
                        <Link to="/newsletter">
                            <div onClick={() => (scrollToTop())} >Newsletter</div>
                        </Link>
                    </li>
                    <li>
                        <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=2BN3DXG5MMXVU&item_name=All+proceeds+directly+fund+Rae%27s+education%21&currency_code=USD&source=url">Donate</a>
                    </li>

                </ul>
            </div>
        </div>
    )
}

export default MobileMenu;