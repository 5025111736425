import React from 'react';
import ReactMarkdown from 'react-markdown'

export default class MarkdownConversion extends React.Component {
    state = {terms: null}

    componentWillMount() {
        fetch(this.props.markdownDoc).then((response) => response.text()).then((text) => {
          this.setState({ terms: text })
        })
      }

    render() {
        return (
            <div className="test">
                {/* Render the markdown component */}
                <ReactMarkdown 
                    escapeHtml={true}
                    source={this.state.terms} 
                />
            </div>
        );
    }
}
