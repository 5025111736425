import React from 'react';


function AuthorBlock(props) {
    return (
        <div>
            <div className="uk-flex uk-flex-center uk-margin-top uk-margin-bottom author_block not_mobile" style={{paddingTop: 30}}>
                <div className="uk-flex-middle uk-align-center uk-width-1-2" uk-grid="true">
                    <div className="uk-width-auto">
                        <img className="uk-border-circle uk-align-left" width="170" height="170" src={props.avatar}  />
                    </div>

                    <div className="uk-width-auto" style={{paddingTop: 18}}>
                        <h2 className="uk-flex-left blurb_text">{props.author}</h2>
                        <p className="uk-flex-left descriptionText blurb_text">{props.authorBlurb}</p>
                        <p className="uk-flex-left uk-text-meta blurb_text"><time datetime="2016-04-01T19:00">{props.date}</time></p>
                    </div>
                </div>
            </div>

            <div className="uk-flex uk-flex-center uk-margin-top author_block mobile" style={{paddingTop: 5}}>
                <div className="uk-flex-middle uk-align-center uk-width-1-2" uk-grid="true">
                    <div className="uk-width-auto">
                        <img className="uk-border-circle uk-align-left" width="50" height="50"  src={props.avatar} />
                        <p className="uk-text-meta blurb_text" style={{paddingLeft: 0}}><time datetime="2016-04-01T19:00">{props.date}</time></p>

                    </div>

            
                </div>
            </div>
        </div>
    )
}

export default AuthorBlock