import React from 'react';
import './App.css';

import BaseCard3 from './Components/BaseCard3.js';
import Content from './Components/Content.js';
import Sidebar from './Components/Sidebar.js';
import MarkdownConversion from './Components/MarkdownConversion.js';
import Newsletter from "./Components/Newsletter.js";
import Footer from './Components/Footer.js';

import tester from './Components/markdown/posts/markdown1.md'
import about from './Components/markdown/About.md'
import store from './Components/markdown/Store coming soon.md'

import bamflogo from './svg/bamflogo.svg';
import circlelogo from './svg/circlelogo.svg';
import mediumlogo from './svg/mediumlogo.svg';
import smallerlogo from './svg/smallerlogo.svg';
import smalllogo from './svg/smalllogo.svg';

import './fontFile/stylesheet.css';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

// single values
export var shareBlurbText = "I'm supporting The BAMF Blog with a share, check it out!    "

// functions
export const mobileRoutesArray = [
    <Route path="/about">
        <MarkdownConversion markdownDoc={about} />
    </Route>,
    <Route path="/store">
        <MarkdownConversion markdownDoc={store} />
    </Route>,
    <Route path="/newsletter">
        test
    </Route>
]

export function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};

