import React from 'react';

function AboutBios(props) {
    return (
        <div>
            <div className="uk-flex uk-flex-center uk-margin-top uk-margin-bottom author_block not_mobile" style={{ paddingTop: 30 }}>
                <div className="uk-flex-middle uk-align-center uk-width-2-3" uk-grid="true">
                    <div className="uk-width-auto">
                        <img className="uk-border-circle uk-align-left" width="170" height="170" src={props.avatar} style={{ paddingTop: 30 }} />
                    </div>

                    <div className="uk-width-auto" style={{ paddingTop: 18 }}>
                        <h2 className="uk-flex-left blurb_text">{props.author}</h2>
                        <p className="uk-flex-left descriptionText blurb_text" align="left">{props.authorBlurb}</p>
                    </div>
                </div>
            </div>

            <div className="uk-flex uk-flex-center uk-margin-top author_block mobile" style={{ paddingTop: 5 }}>
                <div className="uk-flex-middle uk-align-center" uk-grid="true">
                    <div className="">
                        <img className="uk-border-circle uk-align-center uk-width-2-3" width="200" height="200" style={{ paddingRight: 30 }} src={props.avatar} />
                        <div>
                            <h2 className="uk-flex-left blurb_text" style={{ paddingRight: 20 }}>{props.author}</h2>
                            <p className="uk-flex-left descriptionText blurb_text" align="left" style={{ paddingRight: "4%" }}>{props.authorBlurb}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutBios