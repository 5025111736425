import React from "react";

function Footer() {
    return (
        <div className="uk-width-2-3 uk-align-center">
            <div className="not_mobile">
                <div className="uk-align-left uk-margin-top">
                    <p className="uk-text-meta">Bay Area Millenial Family © 2020</p>
                </div>
                <div align="right">
                    <a href="https://twitter.com/thebamfblog"><span className="uk-margin-small-right" uk-icon="icon: twitter; ratio: 2"></span></a>
                    <a href="https://www.instagram.com/bayareamillennialfamily/"><span className="uk-margin-small-right" uk-icon="icon: instagram; ratio: 2"></span></a>
                    <a href="https://github.com/ogjaylowe/deploy_test"><span className="" uk-icon="icon: github; ratio: 2"></span></a>
                </div>

            </div>

            <div className="mobile uk-margin-top">
                <br />
                <br />
                <br />
                <br />
                <div align="center">
                    <a href="https://twitter.com/thebamfblog"><span className="uk-margin-small-right" uk-icon="icon: twitter; ratio: 2"></span></a>
                    <a href="https://www.instagram.com/bayareamillennialfamily/"><span className="uk-margin-small-right" uk-icon="icon: instagram; ratio: 2"></span></a>
                    <a href="https://github.com/ogjaylowe/deploy_test"><span className="" uk-icon="icon: github; ratio: 2"></span></a>
                </div>
                <div className="uk-align-center uk-margin-top">
                    <p className="uk-text-meta">Bay Area Millennial Family © 2020</p>
                </div>

            </div>
        </div>
    )
}

export default Footer;