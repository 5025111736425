import React from 'react';
import { Link } from "react-router-dom";
import { scrollToTop } from "../config.js";

function BaseCard3(props) {
    return (
        <div>

            <div className="uk-width-2-3@m uk-align-center uk-margin-top">

                <Link onClick={() => (scrollToTop())} to={props.link}>
                    <img
                        className="uk-margin-top"
                        src={props.splash}
                        width="650"
                        height="875"
                        style={{
                            paddingTop: "2%",
                            paddingBottom: "2%",
                        }}
                    />
                </Link>

                <div className="uk-text-center" uk-grid="true">

                    <div className="uk-width-auto">
                        <img
                            className="not_mobile" src={props.avatar}
                            width="125"
                            height="125"
                            style={{ paddingTop: 30 }}
                        />
                    </div>

                    <div className="uk-width-expand">
                        <h3 className="mobile" align="left">{props.title}</h3>
                        <h3 className="not_mobile uk-heading-divider" align="left">{props.title}</h3>

                        <p align="left" className="uk-margin-right uk-text-trun blurb_text">
                            {props.blurb}
                            <Link onClick={() => (scrollToTop())} to={props.link}>
                                <span className="uk-icon" uk-icon="icon: arrow-right; ratio: 1">... Read More</span>
                            </Link>
                        </p>

                    </div>
                </div>

            </div>

        </div>
    )
}

export default BaseCard3